export const TIMEZONES = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Saigon",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const CREATE_CHANNEL_ERROR = [
  {
    code: "name_taken",
    message: "A channel cannot be created with the given name",
  },
  {
    code: "restricted_action",
    message: "A team preference prevents the authenticated user from creating channels",
  },
  {
    code: "no_channel",
    message: "Value passed for name was empty",
  },
  {
    code: "missing_scope",
    message: "The token used is not granted the specific scope permissions required to complete this request",
  },
  {
    code: "invalid_name_required",
    message: "Value passed for name was empty",
  },
  {
    code: "invalid_name_punctuation",
    message: "Value passed for name contained only punctuation",
  },
  {
    code: "invalid_name_maxlength",
    message: "Value passed for name exceeded max length",
  },
  {
    code: "invalid_name_specials",
    message: "Value passed for name contained unallowed special characters or upper case characters",
  },
  {
    code: "invalid_name",
    message: "Value passed for name was invalid",
  },
  {
    code: "too_many_convos_for_team",
    message: "The workspace has exceeded its limit of public and private channels",
  },
  {
    code: "too_many_convos_for_app_on_team",
    message: "This app has exceeded its per-workspace limit of public and private channels",
  },
  {
    code: "cannot_create_channel",
    message: "This channel is unable to be created",
  },
  {
    code: "missing_argument",
    message: "A required argument is missing",
  },
  {
    code: "not_authed",
    message: "No authentication token provided",
  },
  {
    code: "invalid_auth",
    message:
      "Some aspect of authentication cannot be validated. Either the provided token is invalid or the request originates from an IP address disallowed from making the request",
  },
  {
    code: "access_denied",
    message: "Access to a resource specified in the request is denied",
  },
  {
    code: "account_inactive",
    message: "Authentication token is for a deleted user or workspace when using a bot token",
  },
  {
    code: "token_revoked",
    message:
      "Authentication token is for a deleted user or workspace or the app has been removed when using a user token",
  },
  {
    code: "token_expired",
    message: "Authentication token has expired",
  },
  {
    code: "no_permission",
    message:
      "The workspace token used in this request does not have the permissions necessary to complete the request. Make sure your app is a member of the conversation it's attempting to post a message to",
  },
  {
    code: "org_login_required",
    message:
      "The workspace is undergoing an enterprise migration and will not be available until migration is complete",
  },
  {
    code: "ekm_access_denied",
    message: "Administrators have suspended the ability to post a message",
  },
  {
    code: "not_allowed_token_type",
    message: "The token type used in this request is not allowed",
  },
  {
    code: "method_deprecated",
    message: "The method has been deprecated",
  },
  {
    code: "deprecated_endpoint",
    message: "The endpoint has been deprecated",
  },
  {
    code: "two_factor_setup_required",
    message: "Two factor setup is required",
  },
  {
    code: "enterprise_is_restricted",
    message: "The method cannot be called from an Enterprise",
  },
  {
    code: "is_bot",
    message: "This method cannot be called by a bot user",
  },
  {
    code: "user_is_ultra_restricted",
    message: "This method cannot be called by a single channel guest",
  },
  {
    code: "team_access_not_granted",
    message: "The token used is not granted the specific workspace access required to complete this request",
  },
  {
    code: "invalid_arguments",
    message:
      "The method was either called with invalid arguments or some detail about the arguments passed are invalid, which is more likely when using complex arguments like blocks or attachments",
  },
  {
    code: "invalid_arg_name",
    message:
      "The method was passed an argument whose name falls outside the bounds of accepted or expected values. This includes very long names and names with non-alphanumeric characters other than _. If you get this error, it is typically an indication that you have made a very malformed API call",
  },
  {
    code: "invalid_array_arg",
    message: "The method was passed an array as an argument. Please only input valid strings",
  },
  {
    code: "invalid_charset",
    message:
      "The method was called via a POST request, but the charset specified in the Content-Type header was invalid. Valid charset names are: utf-8 iso-8859-1",
  },
  {
    code: "invalid_form_data",
    message:
      "The method was called via a POST request with Content-Type application/x-www-form-urlencoded or multipart/form-data, but the form data was either missing or syntactically invalid",
  },
  {
    code: "invalid_post_type",
    message:
      "The method was called via a POST request, but the specified Content-Type was invalid. Valid types are: application/json application/x-www-form-urlencoded multipart/form-data text/plain",
  },
  {
    code: "missing_post_type",
    message:
      "The method was called via a POST request and included a data payload, but the request did not include a Content-Type header",
  },
  {
    code: "team_added_to_org",
    message:
      "The workspace associated with your request is currently undergoing migration to an Enterprise Organization. Web API and other platform operations will be intermittently unavailable until the transition is complete",
  },
  {
    code: "ratelimited",
    message: "The request has been ratelimited. Refer to the Retry-After header for when to retry the request",
  },
  {
    code: "accesslimited",
    message: "Access to this method is limited on the current network",
  },
  {
    code: "request_timeout",
    message: "The method was called via a POST request, but the POST data was either missing or truncated",
  },
  {
    code: "service_unavailable",
    message: "The service is temporarily unavailable",
  },
  {
    code: "fatal_error",
    message:
      "The server could not complete your operation(s) without encountering a catastrophic error. It's possible some aspect of the operation succeeded before the error was raised",
  },
  {
    code: "internal_error",
    message:
      "The server could not complete your operation(s) without encountering an error, likely due to a transient issue on our end. It's possible some aspect of the operation succeeded before the error was raised",
  },
];

export const EVERY_DAYS = [
  {
    code: "monday",
    value: "Mon",
  },
  {
    code: "tuesday",
    value: "Tue",
  },
  {
    code: "wednesday",
    value: "Wed",
  },
  {
    code: "thursday",
    value: "Thu",
  },
  {
    code: "friday",
    value: "Fri",
  },
];

export const HOURS = [
  {
    code: "0",
    value: "12:00 am",
  },
  {
    code: "1",
    value: "1:00 am",
  },
  {
    code: "2",
    value: "2:00 am",
  },
  {
    code: "3",
    value: "3:00 am",
  },
  {
    code: "4",
    value: "4:00 am",
  },
  {
    code: "5",
    value: "5:00 am",
  },
  {
    code: "6",
    value: "6:00 am",
  },
  {
    code: "7",
    value: "7:00 am",
  },
  {
    code: "8",
    value: "8:00 am",
  },
  {
    code: "9",
    value: "9:00 am",
  },
  {
    code: "10",
    value: "10:00 am",
  },
  {
    code: "11",
    value: "11:00 am",
  },
  {
    code: "12",
    value: "12:00 pm",
  },
  {
    code: "13",
    value: "1:00 pm",
  },
  {
    code: "14",
    value: "2:00 pm",
  },
  {
    code: "15",
    value: "3:00 pm",
  },
  {
    code: "16",
    value: "4:00 pm",
  },
  {
    code: "17",
    value: "5:00 pm",
  },
  {
    code: "18",
    value: "6:00 pm",
  },
  {
    code: "19",
    value: "7:00 pm",
  },
  {
    code: "20",
    value: "8:00 pm",
  },
  {
    code: "21",
    value: "9:00 pm",
  },
  {
    code: "22",
    value: "10:00 pm",
  },
  {
    code: "23",
    value: "11:00 pm",
  },
];

export const COMPARE_TYPES = [
  {
    value: 1,
    label: "Person vs Person",
  },
  {
    value: 2,
    label: "Person vs Team",
  },
  {
    value: 3,
    label: "Team vs Team",
  },
  {
    value: 4,
    label: "Person vs Company",
  },
  {
    value: 5,
    label: "Team vs Company",
  },
];
export const PERSON_VS_PERSON = 1;
export const PERSON_VS_TEAM = 2;
export const TEAM_VS_TEAM = 3;
export const PERSON_VS_COMPANY = 4;
export const TEAM_VS_COMPANY = 5;
export const BASE_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000/api";
export const PUBLIC_ROUTE = [
  "/",
  "questionnaire",
  "login",
  "workstyle",
  "persona",
  "signup",
  "schedule",
  "privacy-policy",
  "terms",
  "pulse",
];
export const QUESTION_ROUTE = ["schedule", "questionnaire", "pulse"];
export const USER_ROLE = {
  USER: "user",
  ADMIN: "admin",
  MANAGER: "manager",
  OBSERVER: "observer",
};
export const PULSE = {
  color: "CF23D3",
  image: "/MegaPulseVisual.png",
};

export const WELLNESS = {
  color: "FECA30",
  image: "/Wellness.png",
  description: "Your weekly Wellbeing pulse is ready.",
};

export const PsychSafety = {
  color: "23D3CF",
  image: "/PsychologicalSafetyVisual.png",
  description: "Your monthly Team Dynamics pulse is ready.",
};

export const Meaningfulness = {
  color: "1A9BF0",
  image: "/Meaningfulness.png",
  description: "Your monthly Meaningful Work pulse is ready.",
};

export const FEED_ACTION_TYPE = {
  NEW_WORKSTYLE: "New_Workstyle",
  NEW_SCHEDULE: "New_Schedule",
  PULSE_READY: "Pulse_Ready",
};

export const SHOW_WORKSTYLE_NOTIFICATION = {
  NO_ANSWER: "-1",
  ANSWERED: "0",
  SHOWRED: "1",
};

export const PSYCH_SAFETY_URL = "https://learn.heyharmonic.com/en/articles/1-psych-safety-101";
export const MEANING_FULLESS_URL = "https://learn.heyharmonic.com/en/articles/2-meaningful-work";
export const WELLNESS_URL = "https://learn.heyharmonic.com/en/articles/3-wellbeing";
export const WORKSTYLE_CALCULATOR_TIME = 7000;
export const FEATURES = [
  {
    code: "discover",
    name: "Discover",
  },
  {
    code: "profile",
    name: "Profile",
  },
  {
    code: "workstyle",
    name: "Workstyle",
  },
  {
    code: "coworkers",
    name: "Coworkers",
  },
  {
    code: "people",
    name: "People",
  },
  {
    code: "teams",
    name: "Teams",
  },
  {
    code: "settings",
    name: "Settings",
  },
];
export const SURVEY_BASIC = [
  {
    code: 1,
    name: "nickname",
    type: "basic",
  },
  // {
  //   code: 2,
  //   name: "pronouns",
  //   type: "basic",
  // },
  {
    code: 3,
    name: "dob",
    type: "basic",
  },
  {
    code: 4,
    name: "role_at_work",
    type: "basic",
  },
  {
    code: 5,
    name: "start_date_at_company",
    type: "basic",
  },
  {
    code: 6,
    name: "location",
    type: "basic",
  },
  {
    code: 7,
    name: "work_days",
    type: "basic",
  },
];

export const SURVEY_INTERESTS_VALUE = [
  {
    code: "1",
    name: "listening",
    type: "value",
  },
  {
    code: "2",
    name: "reading",
    type: "value",
  },
  {
    code: "3",
    name: "sport_arts",
    type: "value",
  },
  {
    code: "3",
    name: "food_travel",
    type: "value",
  },
  {
    code: "4",
    name: "other_interests",
    type: "value",
  },
  {
    code: "5",
    name: "values",
    type: "value",
  },
  {
    code: "6",
    name: "goals",
    type: "value",
  },
];

export const SURVEY_BASIC_PUBLIC = [
  {
    code: 8,
    name: "name",
    type: "basic",
  },
  {
    code: 9,
    name: "email",
    type: "basic",
  },
  // {
  //   code: 1,
  //   name: "nickname",
  //   type: "basic",
  // },
  // {
  //   code: 2,
  //   name: "pronouns",
  //   type: "basic",
  // },
  // {
  //   code: 3,
  //   name: "dob",
  //   type: "basic",
  // },
  // {
  //   code: 4,
  //   name: "role_at_work",
  //   type: "basic",
  // },
  // {
  //   code: 5,
  //   name: "start_date_at_company",
  //   type: "basic",
  // },
  // {
  //   code: 6,
  //   name: "location",
  //   type: "basic",
  // },
  // {
  //   code: 7,
  //   name: "work_days",
  //   type: "basic",
  // },
];

export const interestColors = [
  { media: "podcast", color: "CF23D3" },
  { media: "Music", color: "008FFB" },
  { media: "audiobook", color: "23D3CF" },
  { media: "Books", color: "EE5368" },
  { media: "magazine", color: "FECA30" },
  { media: "sport", color: "CF23D3" },
  { media: "art", color: "008FFB" },
  { media: "food", color: "FECA30" },
  { media: "travel", color: "23D3CF" },
  { media: "other", color: "EE5368" },
];

export const VALUES = [
  "Accountability",
  "Adaptability",
  "Adventure",
  "Advocacy",
  "Altruism",
  "Ambition",
  "Analytical Thinking",
  "Animal care",
  "Biodiversity",
  "Buying local",
  "Celebration",
  "Climate Action",
  "Cognitive Flexibility",
  "Collaboration",
  "Communication",
  "Community",
  "Compassion",
  "Continuous Improvement",
  "Cooperation",
  "Courage",
  "Creativity",
  "Critical Thinking",
  "Curiosity",
  "Customer Focus",
  "Devotion",
  "Diligence",
  "Eco-conscious",
  "Ecological Integrity",
  "Empathy",
  "Enlightenment",
  "Environmental Awareness",
  "Environmental Education",
  "Ethical Behavior",
  "Excellence",
  "Fairness",
  "Faith",
  "Flexibility",
  "Forgiveness",
  "Friendship",
  "Fun",
  "Generosity",
  "Goal-oriented",
  "Gratitude",
  "Green Living",
  "Happiness",
  "Harmony",
  "Harmony with Nature",
  "Honesty",
  "Humility",
  "Humour",
  "Inclusivity",
  "Initiative",
  "Inner Peace",
  "Inner Strength",
  "Innovation",
  "Insight",
  "Integrity",
  "Joy",
  "Kindness",
  "Knowledge",
  "Lifelong Learning",
  "Lightheartedness",
  "Loyalty",
  "Meditation",
  "Mindfulness",
  "Mutual Respect",
  "Nature Conservation",
  "Open-mindedness",
  "Optimism",
  "Organic Living",
  "Patience",
  "Perseverance",
  "Play",
  "Positivity",
  "Problem-solving",
  "Professionalism",
  "Punctuality",
  "Purpose",
  "Rationality",
  "Recycling",
  "Reflection",
  "Relationships",
  "Reliability",
  "Renewable Energy",
  "Resilience",
  "Resourcefulness",
  "Respect",
  "Respect for Nature",
  "Responsibility",
  "Responsibility to Future Generations",
  "Reverence",
  "Sacredness",
  "Scientific Thinking",
  "Self-awareness",
  "Self-discipline",
  "Servant Leadership",
  "Service",
  "Simplicity",
  "Skepticism",
  "Social Justice",
  "Social Responsibility",
  "Solidarity",
  "Spiritual Growth",
  "Spontaneity",
  "Stewardship",
  "Strategic Thinking",
  "Support",
  "Sustainability",
  "Teamwork",
  "Thoughtfulness",
  "Tolerance",
  "Transcendence",
  "Trustworthiness",
  "Unity",
  "Waste Reduction",
  "Wildlife Protection",
  "Wisdom",
  "Work-life Balance",
];

// "movie"
// "podcast"(1)
// "ebook"(1)
// "tvShow"
// "music"(1),
// audio book(0),
// magazines(0),
// sport(0),
// art (0),
// food(0),
// travel(0),
// other(0)
