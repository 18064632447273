import _ from "lodash";
import moment from "moment";
import { format } from "date-fns";

const Helper = {
  getFirstCharacter: function (sentence: string) {
    const words = sentence.split(" ");
    return words
      .slice(0, 2)
      .map((item) => {
        return item[0];
      })
      .join("");
  },

  validateEmail: function (email: string) {
    // Regular expression for email validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;

    if (!emailPattern.test(email)) {
      return false;
    } else {
      return true;
    }
  },

  convertUtcToLocalString: function (time: string) {
    const utcDate = new Date(time);
    return moment.utc(utcDate).local().format("MMMM");
  },

  convertDateToLocalString: function (time: string) {
    const date = new Date(time);
    const formattedDate = moment.utc(date).local().format("do of MMMM, yyyy");
    return formattedDate;
  },

  getTimeZone: function () {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },

  isFeature: function (feature: string, companyFeatures: string[]) {
    const itemFeature = _.find(companyFeatures, function (item: any) {
      return item.code === feature;
    });
    return itemFeature ? true : false;
  },

  // convert string '2024-08-01' to moment
  convertStringToDate: function (date: string) {
    return moment(date, "YYYY-MM-DD");
  },

  // replace(/\n/g, "<br />")
  repectEndline: function (text: string) {
    if(!text) return "";
    return text.replace(/\n/g, "<br />");
  },
};

export default Helper;
