import * as React from "react";
import authService from "../auth.service";
import AvatarText from "./avatar-text";
import { USER_ROLE } from "../utils/constant";
import { useRecoilState } from "recoil";
import { token, userProfile } from "../recoil/userProfile";
import { useClerk } from "@clerk/clerk-react";

type Props = {
  closeModal: Function;
  currentUser?: any;
  canEditProfile?: boolean;
  showAdminEditProfile?: Function;
  providerId?: string;
  onClickOutside?: any;
};
const MainMenu = (props: Props) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const ref: any = React.useRef(null);
  const [currentToken, setCurrentToken] = useRecoilState(token);
  const [currentUser, setCurrentUser] = useRecoilState(userProfile);
  const { signOut } = useClerk();

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        showMenu && setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showMenu]);

  const logOut = async () => {
    const result = await signOut();
    setCurrentToken("");
    setCurrentUser(undefined);
    authService.exchangeLogout();
  };

  return (
    <div className="profile-box ml-15" ref={ref}>
      <button
        onClick={() => setShowMenu(!showMenu)}
        className={`dropdown-toggle bg-transparent border-0 ${showMenu ? "show" : ""}`}
        type="button"
        id="profile"
        aria-label="profile"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <div className="profile-info">
          <div className="info">
            <div className="image">
              {props.currentUser?.picture ? (
                <img style={{maxWidth: "100%", maxHeight: "100%"}} src={props.currentUser?.picture} alt="" />
              ) : (
                <AvatarText
                  name={props.currentUser?.name}
                  profile_color={props.currentUser?.profile_color}
                  width={45}
                  height={45}
                  fontSize={16}
                />
              )}
            </div>
            <div>
              <h6 className="fw-500">{props.currentUser?.name}</h6>
              {props.currentUser?.role === USER_ROLE.ADMIN || props.currentUser?.role === USER_ROLE.MANAGER ? (
                <p>{props.currentUser?.role}</p>
              ) : (
                <p>Team member</p>
              )}
            </div>
          </div>
        </div>
      </button>
      <ul
        className={`dropdown-menu dropdown-menu-end ${showMenu ? "show" : ""}`}
        aria-labelledby="profile"
        style={
          showMenu
            ? {
                position: "absolute" as "absolute",
                inset: "0px 0px auto auto",
                margin: "0px",
                transform: "translate3d(0px, 48px, 0px)",
                width: "auto",
              }
            : undefined
        }>
        <li role="listitem">
          {/* <SignOutButton>
            <i onClick={() => logOut()} className="lni lni-exit"></i> Sign Out{" "}
          </SignOutButton> */}
          <a href="#" onClick={() => logOut()}>
            {" "}
            <i className="lni lni-exit"></i> Sign Out{" "}
          </a>
        </li>
      </ul>
    </div>
  );
};
export default MainMenu;
