import React from "react";
import MainMenu from "../components/main-menu";
import PlainAdminButton from "../common/PlainAdmin-Button";
import { useRecoilState } from "recoil";
import { userProfile } from "../recoil/userProfile";
import _ from "lodash";

type Props = {
  onChangeShowMenu: Function;
  currentUser: any;
  icon: string;
};

const Sidebar = (props: Props) => {
  const [currentRecolUser] = useRecoilState(userProfile);
  const checkShowMenuLeft = _.find(["/workstyle-time", "/organization"], (path) => {
    return path === window.location.pathname;
  })
    ? false
    : true;

  const checkFullWidh = _.find(["/questionnaire"], (path) => {
    return path === window.location.pathname;
  });
  return (
    <header className="header">
      <div className={`container-fluid ${checkFullWidh ? "pb-0" : ""}`}>
        <div className="row">
          <div className="col-lg-5 col-md-5 col-6">
            <div className="header-left d-flex align-items-center">
              <div className="menu-toggle-btn mr-15">
                {/* <button
                  onClick={() => props.onChangeShowMenu()}
                  id="menu-toggle"
                  className="main-btn primary-btn btn-hover">
                  <i className={`lni ${props.icon}`}></i>
                  Menu
                </button> */}
                {checkShowMenuLeft ? (
                  <PlainAdminButton type={"primary-btn btn-hover"} onClick={() => props.onChangeShowMenu()}>
                    <i className={`lni ${props.icon}`}></i>
                    Menu
                  </PlainAdminButton>
                ) : (
                  <>
                    <div className="header-left d-flex align-items-center">
                      <a href="/">
                        <img src={process.env.PUBLIC_URL + "/harmonic-logo-full-colour.png"} width="175" alt="logo" />
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-6">
            <div className="header-right">
              {(props.currentUser || currentRecolUser) && (
                <MainMenu closeModal={() => {}} currentUser={props.currentUser} onClickOutside={undefined} />
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Sidebar;
