import Cookie from "js-cookie";
import QueryString from "query-string";
import Auth from "./auth";
import { SHOW_WORKSTYLE_NOTIFICATION } from "./utils/constant";

const CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID || "2197353973604.3533255374533";
const REDIRECT_URL = process.env.REACT_APP_SLACK_CLIENT_REDIRECT
  ? process.env.REACT_APP_SLACK_CLIENT_REDIRECT
  : "https://app.affinity.me";

export type SlackAuthResult = {
  tokenKey: string | undefined;
  affinity_team_id: string;
  affinity_user_provider_id: string;
};

class Service {
  tokenKey: string;

  constructor() {
    this.tokenKey = "affinity_token";
  }

  getToken(): string | undefined {
    return Cookie.get(this.tokenKey);
  }

  setToken(token: string) {
    return Cookie.set(this.tokenKey, token);
  }

  getRefreshToken(): string | undefined {
    return Cookie.get("refresh_token");
  }

  getUserRole(): string | undefined {
    return Cookie.get("affinity_user_role");
  }

  setUserRole(role: string) {
    return Cookie.set("affinity_user_role", role);
  }

  getLoginState(): string | undefined {
    return Cookie.get("login_state");
  }

  setLoginState(state: string) {
    return Cookie.set("login_state", state);
  }

  setRefreshToken(refresh_token: string) {
    return Cookie.set("refresh_token", refresh_token);
  }

  setExpiredTime(exp: string) {
    return Cookie.set("affinity_token_expire", exp);
  }

  getType(): string | undefined {
    return Cookie.get("type");
  }

  getProviderId(): string | undefined {
    return Cookie.get("affinity_user_provider_id");
  }

  getTeamId(): string | undefined {
    return Cookie.get("affinity_team_id");
  }

  setTeamId(id: string): string | undefined {
    return Cookie.set("affinity_team_id", id);
  }

  getCompanyId(): string | undefined {
    return Cookie.get("affinity_company_id");
  }

  setCompanyId(company_id: string) {
    return Cookie.set("affinity_company_id", company_id);
  }

  setFirstName(first_name: string) {
    return Cookie.set("first_name", first_name);
  }

  getFirstName(): string | undefined {
    return Cookie.get("first_name");
  }

  setCompanyName(company_name: string) {
    return Cookie.set("company_name", company_name);
  }

  getCompanyName(): string | undefined {
    return Cookie.get("company_name");
  }

  setShowNotificationWorkstyle(show: string) {
    return Cookie.set("show_notification", show);
  }

  getShowNotificationWorkstyle(): string | undefined {
    return Cookie.get("show_notification");
  }

  setAffinityStarted(show: string) {
    return Cookie.set("affinity_started", show);
  }

  getAffinityStarted(): string | undefined {
    return Cookie.get("affinity_started");
  }

  async isAuthenticated(): Promise<boolean> {
    let tokenCookie = Cookie.get(this.tokenKey);
    // let tokenCookieExpire = Cookie.get('affinity_token_expire')

    //lets check the simplest thing first. do we even have a cookie stored for token?
    if (!tokenCookie) {
      return false;
    }

    //TODO, openID is now shortlived tokens and needs to be exchanged with the refresh token
    //when it expires (5mins)
    // if (tokenCookieExpire && moment().unix() > moment(tokenCookieExpire).unix()) {
    //    return false
    // }

    return true;
  }

  async exchangeAuthenticationCode(): Promise<SlackAuthResult | null> {
    let queryString = QueryString.parse(window.location.search);
    if (queryString.code) {
      try {
        const result = await Auth.saveToken(queryString.code);
        let cookieParams: any = result;
        cookieParams["type"] = "slack";
        this._setCookies(cookieParams);

        return result;
      } catch (e) {
        console.log(e);
        // window.location.href = "/";
      }
    }

    return null;
  }

  async exchangeAuthenticationNonSlack(data: any, type?: string, redirectUrl?: string): Promise<any | null> {
    Cookie.set(this.tokenKey, data.token);
    Cookie.set("affinity_team_id", data.affinity_team_id);
    Cookie.set("affinity_user_provider_id", data.affinity_user_provider_id);
    Cookie.set("type", "web");
    Cookie.set("refresh_token", data.refresh_token);
    Cookie.set("affinity_token_expire", data.exp);
    Cookie.set("affinity_user_role", data.role);
    Cookie.set("login_state", data.login_state);
    this.setFirstName(data?.profile?.first_name);
    this.setCompanyName(data?.profile?.company?.name);
    this.setAffinityStarted(data?.profile?.company?.affinity_started);
    // this.setShowNotificationWorkstyle(SHOW_WORKSTYLE_NOTIFICATION.ANSWERED);
  }

  async redirectUrl(data: any, type?: string, redirectUrl?: string) {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      this.redirectRole(data.role, data.affinity_user_provider_id);
      this.redirectRole(data.role, data.affinity_user_provider_id);
    }
  }

  redirectRole(role: string | undefined, affinity_user_provider_id: string | undefined) {
    if (role === "admin" || role === "manager") {
      window.location.href = "/discover";
    } else {
      window.location.href = `/play-book`;
    }
  }

  authenticate(): void {
    let user_scope =
      "team:read users:read users:read.email users.profile:read channels:read groups:read mpim:read im:read channels:write groups:write mpim:write im:write";
    let redirectUrl = REDIRECT_URL;
    let url = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&user_scope=${user_scope}&redirect_uri=${encodeURIComponent(
      redirectUrl
    )}`;
    window.location.href = url;
  }

  // grant permision to add workspace to Affinity app
  addToSlack(): void {
    let user_scope =
      "team:read users:read users:read.email users.profile:read channels:read groups:read mpim:read im:read channels:write groups:write mpim:write im:write";
    let scope =
      "app_mentions:read channels:history channels:manage chat:write chat:write.public commands groups:history groups:write im:history im:write incoming-webhook mpim:history mpim:write reactions:read team:read usergroups:write users.profile:read users:read users:write channels:read groups:read mpim:read im:read";
    let redirectUrl = REDIRECT_URL;
    let url = `https://slack.com/oauth/v2/authorize?scope=${scope}&user_scope=${user_scope}&redirect_uri=${encodeURIComponent(
      redirectUrl
    )}&client_id=${CLIENT_ID}`;
    window.location.href = url;
  }

  // todo: grant permission
  grantPermissions(): void {
    //build up redirect URL
    //TODO portal URL replacement
    let user_scope = "channels:read,groups:read,mpim:read,im:read,channels:write,groups:write,mpim:write,im:write";
    let url = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&user_scope=${user_scope}`;
    window.location.href = url;
  }

  private _setCookies(params: { [cookieKey: string]: string }) {
    for (const param in params) {
      Cookie.set(param, params[param], { secure: true });
    }
  }

  exchangeLogout(redirect = true) {
    Cookie.remove(this.tokenKey);
    Cookie.remove("affinity_team_id");
    Cookie.remove("affinity_user_provider_id");
    this.setShowNotificationWorkstyle('');
    if(redirect)
      window.location.href = "/";
  }
}

const authService = new Service();
export default authService;
